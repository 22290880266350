import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  action: {
    "&:hover": {
      "& $avatar": {
        backgroundColor: theme.palette.innoBlue.main,
      },
      "& $title": {
        color: theme.palette.innoBlue.main,
      },
    },
  },
  avatar: {
    transition: theme.transitions.create("background-color"),
  },
  title: {
    transition: theme.transitions.create("background-color"),
  },
}));

export default useStyles;
