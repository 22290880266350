import React from "react";
import Seo from "../../../components/Seo";
import Hero from "../../../views/Company/JoinUs/Hero";
import Jobs from "../../../views/Company/JoinUs/Jobs";

const JoinUsPage = () => {
  return (
    <React.Fragment>
      <Seo
        title="Nous rejoindre"
        description="Vous souhaitez rejoindre une équipe à taille humaine ? Relever les
            défis auxquels notre logiciel pour association doit répondre ?
            Nous cherchons aujourd’hui à atteindre une taille à la hauteur de
            nos ambitions pour moderniser et développer nos solutions."
      />
      <Hero />
      <Jobs />
    </React.Fragment>
  );
};

export default JoinUsPage;
