import Container from "@mui/material/Container";
import React from "react";
import SectionTitle from "../../../../components/layout/SectionTitle";
import JOBS from "../../../../util/jobs";
import { COMPANY_JOIN_US_JOBS_SECTION } from "../../../../util/constants";
import JobCard from "./JobCard";
import { classes, StyledSectionWrapper } from "./styles";

const JoinsUsJobs = () => {
  return (
    <StyledSectionWrapper sectionId={COMPANY_JOIN_US_JOBS_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Nos offres" />
        {JOBS.map((i) => (
          <JobCard
            key={i.slug}
            location={i.location}
            slug={i.slug}
            title={i.title}
            type={i.type}
            period={i.period}
          />
        ))}
      </Container>
    </StyledSectionWrapper>
  );
};

export default JoinsUsJobs;
