import styled from "../../../util/styled";
import { PaddingSectionType } from "./props";

const PREFIX = "SectionWrapper";

export const classes = {
  root: `${PREFIX}-root`,
};

const PaddingMap: { [key in PaddingSectionType]: number } = {
  large: 10,
  normal: 8,
  small: 5,
};

export const Root = styled("div")<{
  padding: PaddingSectionType;
}>(({ theme, padding }) => ({
  padding: theme.spacing(PaddingMap[padding], 0),
}));
