export interface IJobBase {
  title: string;
  type: string;
  slug: string | null;
  location?: string;
  period?: string;
}

export interface IJob extends IJobBase {
  id: string;
}

const JOBS: IJob[] = [
  {
    id: "DEVWEBFRONT2022",
    title: "Développeur web Front-end",
    location: "Full remote",
    type: "CDI",
    period: "A pourvoir dès que possible",
    slug: "developpeur-web-frontend-cdi-full-remote",
  },
  {
    id: "SPONTANEE",
    title: "Candidature Spontanée",
    type: "Nous sommes ouverts à découvrir de nouvelles personnalités et de nouveaux talents !",
    slug: null,
  },
];

export default JOBS;
