import SectionWrapper from "../../../../components/layout/SectionWrapper";
import styled from "../../../../util/styled";

const PREFIX = "JoinUsJobs";

export const classes = {
  container: `${PREFIX}-container`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },
}));
