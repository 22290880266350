import clsx from "clsx";
import React from "react";
import { SectionWrapperProps } from "./props";
import { Root } from "./styles";

const SectionWrapper = (props: SectionWrapperProps) => {
  const { children, padding = "large", sectionId, className } = props;

  return (
    <Root id={sectionId} padding={padding} className={className}>
      {children}
    </Root>
  );
};

export default SectionWrapper;
