import Typography from "@mui/material/Typography";
import styled from "../../../util/styled";
import { SectionTitleVariant } from "./props";

const PREFIX = "SectionTitle";

export const classes = {
  root: `${PREFIX}-root`,
};

export const StyledTypography = styled(Typography)<{
  color: SectionTitleVariant;
}>(({ theme, color }) => ({
  marginBottom: theme.spacing(6),
  color: theme.palette[color].main,
})) as typeof Typography;
