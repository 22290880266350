import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { globalClasses } from "../../../../components/layout/MainLayout/styles";
import { COMPANY_JOIN_US_HERO_SECTION } from "../../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const JoinUsHero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper
      padding="normal"
      sectionId={COMPANY_JOIN_US_HERO_SECTION}
      className={globalClasses.innoGreyGradient}
    >
      <Container>
        <Grid
          item
          sm={12}
          justifyContent={isMobile ? "center" : "flex-start"}
          container
          direction="column"
        >
          <Typography
            variant="h3"
            component="h1"
            align={isMobile ? "center" : "inherit"}
            gutterBottom
            className={classes.typo}
          >
            Nous rejoindre
          </Typography>
          <Typography
            variant="body1"
            align={isMobile ? "center" : "inherit"}
            gutterBottom
            className={classes.typo}
          >
            Vous souhaitez rejoindre une équipe à taille humaine ? Relever les
            défis auxquels notre logiciel pour association doit répondre ?<br />{" "}
            Nous cherchons aujourd’hui à atteindre une taille à la hauteur de
            nos ambitions pour moderniser et développer nos solutions.
          </Typography>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default JoinUsHero;
