import React from "react";
import { SectionTitleProps } from "./props";
import { StyledTypography } from "./styles";

const SectionTitle = (props: SectionTitleProps) => {
  const { text, variant = "innoBlue", className } = props;

  return (
    <StyledTypography
      variant="h3"
      component="h2"
      align="center"
      color={variant}
      className={className}
    >
      {text}
    </StyledTypography>
  );
};

export default SectionTitle;
