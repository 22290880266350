import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";
import { handleNavigate } from "../../../../../util";
import { JobCardProps } from "./props";
import useStyles from "./styles";

const JobCard = (props: JobCardProps) => {
  const { location, title, type, slug, period } = props;

  const classes = useStyles();

  const handleClick = () => {
    if (slug) {
      handleNavigate(slug);
    } else {
    }
  };

  return (
    <Card
      sx={{
        mb: 3,
      }}
    >
      <CardActionArea className={classes.action} onClick={handleClick}>
        <CardContent
          sx={{
            display: "flex",
            padding: 3, // equals to theme.spacing(3)
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Typography
              className={classes.title}
              gutterBottom
              variant="h5"
              component="div"
            >
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {type}
              {location ? ` - ${location}` : null}
              {period ? ` - ${period}` : null}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar className={classes.avatar}>
              {slug ? <VisibilityIcon /> : <SendIcon />}
            </Avatar>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default JobCard;
